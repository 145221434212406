import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  convertOrderFormToOrder,
  materialSelectionTypeConverter,
  orderConverter,
} from '@ds-converters';
import { getUniqueId, neverError } from '@ds-helpers';
import { ApiService, DcuplService, UiService } from '@ds-services';
import {
  DS_API_ENDPOINTS,
  DS_BOARD_TYPES,
  DS_CUTOUT_INDIVIDUAL_TEXT,
  DS_CUTOUT_TYPES,
  DS_DIALOG_MAX_SIZE,
  DS_DIALOG_SIZE,
  DsApiOrderResponse,
  DsBoardType,
  DsCutoutSettingsFormGroup,
  DsCutoutType,
  DsCutoutTypeSettingsFields,
  DsDateApi,
  DsErrorDialogInput,
  DsFormSaveDialogInput,
  DsFormSaveDialogOutput,
  DsMaterialChangedPayload,
  DsMaterialSettingsFields,
  DsMaterialSettingsFormGroup,
  DsOrder,
  DsOrderApi,
  DsOrderArticle,
  DsOrderCutoutSettingsFormGroups,
  DsOrderEditData,
  DsOrderFormData,
  DsOrderFormGroups,
  DsOrderFormGroupValues,
  DsOrderFormMeasure,
  DsOrderFormPhotosEmailSendType,
  DsOrderFormStep,
  DsOrderFormStep1Fields,
  DsOrderFormStep2Fields,
  DsOrderFormStep3Fields,
  DsOrderFormStep4Fields,
  DsOrderFormStep5Fields,
  DsOrderFormStepFields,
  DsOrderMaterialSettingsFormGroups,
  DsOrderPreStore,
  DsOrderSubmitMode,
  DsOrderSubmitStatus,
  DsOrderType,
  DsUser,
  DsUserType,
} from '@ds-types';
import { customerAddressValidator } from '@ds-ui/order-form/validators/customer-address.validator';
import { BehaviorSubject, first, Observable, Subject } from 'rxjs';
import { ErrorDialogComponent } from 'src/app/shared/dialogs/error-dialog/error-dialog.component';
import { FormSaveDialogComponent } from 'src/app/shared/dialogs/form-save-dialog/form-save-dialog.component';
import { cutoutSettingsValidator } from '../validators/cutout-settings.validator';
import { fileUploadValidator } from '../validators/file-upload.validator';
import { materialSettingsValidator } from '../validators/material-settings.validator';
import { measureDeliveryValidator } from '../validators/measure-delivery.validator';
import { photosEmailValidator } from '../validators/photos-email.validator';

@Injectable({
  providedIn: 'root',
})
export class OrderFormService {
  private activeStep$$ = new BehaviorSubject<DsOrderFormStep | undefined>(
    undefined
  );

  private _formGroups?: DsOrderFormGroups;
  public materialSettingsFormGroups: DsOrderMaterialSettingsFormGroups = {
    worktop: this.getMaterialSettingsFormGroup(),
    backwall: this.getMaterialSettingsFormGroup(),
    wiper: this.getMaterialSettingsFormGroup(),
    sidepanel: this.getMaterialSettingsFormGroup(),
  };
  public cutoutSettingsFormGroups: DsOrderCutoutSettingsFormGroups = {
    sink: this.getCutoutSettingsFormGroup(),
    hob: this.getCutoutSettingsFormGroup(),
    downdraft: this.getCutoutSettingsFormGroup(),
    energy_box: this.getCutoutSettingsFormGroup(),
  };

  public detectSelectMaterialChanges$ = new Subject<DsMaterialChangedPayload>();

  public orderNumber = '';
  public orderVersion?: DsOrder['versionn'];

  public prefillData?: DsOrderEditData | null;

  public additionalArticlesFromAbas: DsOrderArticle<string>[] = [];

  public isViewMode = false;
  public isPrefillFinished = false;
  public isAnyBoardAdded$ = new BehaviorSubject<boolean>(false);
  public initOrder$ = new Subject<void>();
  public updateOrder$ = new Subject<DsOrderSubmitMode>();
  public orderSubmitStatusChange$ = new Subject<DsOrderSubmitStatus>();
  public lastDeliveryDate: DsDateApi | null = null;
  public lastNatureMeasureDate: DsDateApi | null = null;
  public successOrder$ = new Subject<DsOrder | undefined>();
  public scrollToError$ = new Subject<void>();
  public shouldCheckDeliveryDateChanged = false;

  private preStore$$ = new BehaviorSubject<DsOrderPreStore | undefined>(
    undefined
  );

  constructor(
    private dcuplService: DcuplService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private uiService: UiService,
    private router: Router
  ) {}

  get activeStep$(): Observable<DsOrderFormStep | undefined> {
    return this.activeStep$$.asObservable();
  }

  get formGroups(): DsOrderFormGroups {
    if (!this._formGroups) {
      throw new Error('formGroups is undefined');
    }
    return this._formGroups;
  }

  public async init(user: DsUser, orderNumber: string | null): Promise<void> {
    this._formGroups = this.getDefaultForm();
    this.orderVersion = this.prefillData?.order.versionn;
    this.initValues(user);
    this.preStore$$.next({
      isOverallDirty: false,
      formGroupValues: this.getFormGroupValues(),
    });
    this.orderNumber = orderNumber ?? getUniqueId();
  }

  public destroy(): void {
    this._formGroups = undefined;
    this.preStore$$.next(undefined);
  }

  private getMaterialSettingsFormGroup(): DsMaterialSettingsFormGroup {
    return new FormGroup<{
      [key in DsMaterialSettingsFields]: FormControl;
    }>(
      {
        enabled: new FormControl(false, [Validators.required]),
        material_thickness: new FormControl(undefined),
        article_id: new FormControl(undefined),
        edge_treatment: new FormControl(undefined),
        surface_type: new FormControl(undefined),
        article_max_length: new FormControl(false),
        material_number: new FormControl(undefined),
        amount: new FormControl(undefined),
        position_number: new FormControl(undefined),
      },
      [materialSettingsValidator]
    );
  }

  private getCutoutSettingsFormGroup(): DsCutoutSettingsFormGroup {
    return new FormGroup<{
      [key in DsCutoutTypeSettingsFields]: FormControl;
    }>(
      {
        enabled: new FormControl(false),
        installation_type: new FormControl(undefined),
        is_ind: new FormControl(false),
        ind_text: new FormControl(undefined),
        brand: new FormControl(undefined),
        type: new FormControl(undefined),
        order_number: new FormControl(undefined),
        specify_later: new FormControl(false),
      },
      [cutoutSettingsValidator]
    );
  }

  private getDefaultForm(): DsOrderFormGroups {
    return {
      1: new FormGroup<{ [key in DsOrderFormStep1Fields]: FormControl }>({
        customerRefNr: new FormControl(undefined, [Validators.required]),
        sales_RefNr: new FormControl(undefined, [Validators.required]),
        commission_name: new FormControl(undefined, [
          Validators.required,
          Validators.maxLength(56),
        ]),
        commission_number: new FormControl(undefined, [
          Validators.maxLength(28),
        ]),
        type: new FormControl(undefined, [Validators.required]),
        service_type: new FormControl(undefined),
        condition_date: new FormControl(undefined),
      }),
      2: new FormGroup<{ [key in DsOrderFormStep2Fields]: FormControl }>({}),
      3: new FormGroup<{ [key in DsOrderFormStep3Fields]: FormControl }>(
        {
          files: new FormControl(undefined),
          installation_gap: new FormControl(undefined),
        },
        [fileUploadValidator]
      ),
      4: new FormGroup<{ [key in DsOrderFormStep4Fields]: FormControl }>(
        {
          delivery_type: new FormControl(undefined),
          measure: new FormControl(undefined),
          specify_plan_dimension_later: new FormControl(undefined),
          measure_delivery: new FormControl(undefined),
          montage_package_connection: new FormControl(undefined),
          montage_package_connection_downdraft: new FormControl(undefined),
          montage_package_connection_multi: new FormControl(undefined),
          specify_customer_data_later: new FormControl(undefined),
          customer_name_delivery: new FormControl(undefined),
          customer_street_delivery: new FormControl(undefined),
          customer_zip_delivery: new FormControl(undefined),
          customer_city_delivery: new FormControl(undefined),
          customer_country_delivery: new FormControl(undefined),
          customer_tel_delivery: new FormControl(undefined),
          customer_mobile_delivery: new FormControl(undefined),
          customer_email_delivery: new FormControl(undefined),

          warehouse_address_retail: new FormControl(undefined),
          warehouse_address: new FormControl(undefined),
          warehouse_zip: new FormControl(undefined),
          warehouse_city: new FormControl(undefined),
          warehouse_country: new FormControl(undefined),
          warehouse_tel_retail: new FormControl(undefined),
          warehouse_mobile_retail: new FormControl(undefined),
          warehouse_email_retail: new FormControl(undefined),
        },
        [measureDeliveryValidator, customerAddressValidator]
      ),

      5: new FormGroup<{ [key in DsOrderFormStep5Fields]: FormControl }>(
        {
          deadline_stencil: new FormControl(undefined),
          deadline_changes: new FormControl(undefined),
          deadline_address: new FormControl(undefined),
          deadline_sinktype: new FormControl(undefined),
          deadline_hobtype: new FormControl(undefined),
          deadline_downdraft: new FormControl(undefined),
          deadline_energybox: new FormControl(undefined),
          deadline_natural_measure: new FormControl(undefined),
          nature_measure_date: new FormControl(undefined),
          deadline_material_inspection: new FormControl(undefined),
          deadline_plan_dimension: new FormControl(undefined),
          comment: new FormControl(undefined),
          agb: new FormControl(undefined, [Validators.requiredTrue]),
          data_protection: new FormControl(undefined, [
            Validators.requiredTrue,
          ]),
          data_ok: new FormControl(undefined, [Validators.requiredTrue]),
          delivery_date: new FormControl(undefined),
          material_selection_type: new FormControl(undefined),
          customer_email_delivery: new FormControl(undefined),
          photos_email_send: new FormControl<DsOrderFormPhotosEmailSendType>(
            'me'
          ),
        },
        [photosEmailValidator]
      ),
    };
  }

  public initValues(user: DsUser): void {
    this.isPrefillFinished = false;
    const prefillData = this.prefillData;

    // STEP 1
    this.formGroups[1].setValue({
      commission_name: prefillData?.order.commission_name ?? '',
      commission_number: prefillData?.order.commission_number ?? '',
      customerRefNr: prefillData?.order.customerRefNr
        ? prefillData.order.customerRefNr
        : user.type === 'extern'
          ? user.customerRefNr
          : null,
      sales_RefNr: prefillData?.order.sales_RefNr
        ? prefillData.order.sales_RefNr
        : user.type === 'extern'
          ? user.sales_RefNr
          : '',
      type: prefillData?.order.type ?? 'order',
      service_type: prefillData?.order.service_type ?? '(VERKAUF)',
      condition_date: prefillData?.order.condition_date ?? new Date(),
    });

    // STEP 2

    DS_BOARD_TYPES.forEach((boardType) => {
      this.prefillBoardType(prefillData ?? null, boardType);
    });

    DS_CUTOUT_TYPES.forEach((cutoutType) => {
      this.prefillCutoutType(prefillData ?? null, cutoutType);
    });

    // STEP 3

    this.formGroups[3].setValue({
      files: prefillData?.files ?? [],
      installation_gap: prefillData?.order.installation_gap ?? true,
    });

    // STEP 4

    let measureValue: DsOrderFormMeasure = 'none';

    if (prefillData) {
      if (prefillData.order.measure_nature) {
        measureValue = 'natural';
      } else if (prefillData.order.measure_stencil) {
        measureValue = 'template';
      }
    }

    this.formGroups[4].setValue({
      delivery_type: prefillData?.order.delivery_type ?? 'delivery_retailer',
      montage_package_connection:
        prefillData?.order.montage_package_connection ?? false,
      montage_package_connection_downdraft:
        prefillData?.order.montage_package_connection_downdraft ?? false,
      montage_package_connection_multi:
        prefillData?.order.montage_package_connection_multi ?? false,
      measure: measureValue ?? 'none',
      specify_plan_dimension_later:
        prefillData?.order.specify_plan_dimension_later ?? false,
      measure_delivery: prefillData?.order.measure_delivery ?? null,
      specify_customer_data_later:
        prefillData?.order.specify_customer_data_later ?? false,
      customer_name_delivery: prefillData?.order.customer_name_delivery ?? '',
      customer_street_delivery:
        prefillData?.order.customer_street_delivery ?? '',
      customer_zip_delivery: prefillData?.order.customer_zip_delivery ?? '',
      customer_city_delivery: prefillData?.order.customer_city_delivery ?? '',
      customer_country_delivery:
        prefillData?.order.customer_country_delivery ?? '',
      customer_mobile_delivery:
        prefillData?.order.customer_mobile_delivery ?? '',
      customer_tel_delivery: prefillData?.order.customer_tel_delivery ?? '',
      customer_email_delivery: prefillData?.order.customer_email_delivery ?? '',
      warehouse_address_retail:
        prefillData?.order.warehouse_address_retail ?? '',
      warehouse_address: prefillData?.order.warehouse_address ?? '',
      warehouse_zip: prefillData?.order.warehouse_zip ?? '',
      warehouse_city: prefillData?.order.warehouse_city ?? '',
      warehouse_country: prefillData?.order.warehouse_country ?? '',
      warehouse_tel_retail: prefillData?.order.warehouse_tel_retail ?? '',
      warehouse_mobile_retail: prefillData?.order.warehouse_mobile_retail ?? '',
      warehouse_email_retail: prefillData?.order.warehouse_email_retail ?? '',
    });

    // STEP 5

    const materialSelectionData = prefillData
      ? materialSelectionTypeConverter.toForm(prefillData.order)
      : undefined;

    this.formGroups[5].setValue({
      agb: prefillData?.order.agb ?? false,
      data_protection: prefillData?.order.data_protection ?? false,
      comment: prefillData?.order.comment ?? '',
      customer_email_delivery: prefillData?.order.customer_email_delivery ?? '',
      data_ok: prefillData?.dataOk ?? false,
      delivery_date: prefillData?.order.delivery_date ?? null,
      material_selection_type: materialSelectionData
        ? materialSelectionData.material_selection_type
        : 'none',
      photos_email_send: materialSelectionData
        ? materialSelectionData.photos_email_send
        : 'me',
      deadline_address: prefillData?.order.deadline_address ?? null,
      deadline_stencil: prefillData?.order.deadline_stencil ?? null,
      deadline_changes: prefillData?.order.deadline_changes ?? null,
      deadline_downdraft: prefillData?.order.deadline_downdraft ?? null,
      deadline_energybox: prefillData?.order.deadline_energybox ?? null,
      deadline_hobtype: prefillData?.order.deadline_hobtype ?? null,
      deadline_material_inspection:
        prefillData?.order.deadline_material_inspection ?? null,
      deadline_plan_dimension:
        prefillData?.order.deadline_plan_dimension ?? null,
      deadline_natural_measure:
        prefillData?.order.deadline_natural_measure ?? null,
      nature_measure_date: prefillData?.order.nature_measure_date ?? null,
      deadline_sinktype: prefillData?.order.deadline_sinktype ?? null,
    });

    // VALIDATORS

    if (this.isFieldAvailableByUserType(user.type, 'service_type')) {
      this.formGroups[1].controls.service_type.setValidators(
        Validators.required
      );
      this.formGroups[1].controls.service_type?.updateValueAndValidity();
    }

    if (this.isFieldAvailableByUserType(user.type, 'condition_date')) {
      this.formGroups[1].controls.condition_date.setValidators(
        Validators.required
      );
      this.formGroups[1].controls.condition_date?.updateValueAndValidity();
    }
  }

  private async prefillBoardType(
    prefillData: DsOrderEditData | null,
    boardType: DsBoardType
  ): Promise<void> {
    const article = prefillData?.articles.find(
      (article) => article.boardType === boardType
    );
    if (article) {
      const selectedArticle = await this.dcuplService.getArticleById(
        article.key
      );

      if (selectedArticle) {
        this.materialSettingsFormGroups[boardType].setValue({
          enabled: true,
          material_number: selectedArticle.materialNumber,
          article_id: article.key,
          edge_treatment: article.edge_treatment,
          article_max_length: article.article_max_length,
          surface_type: selectedArticle.surface,
          material_thickness: selectedArticle.thickness,
          amount: article.amount,
          position_number: '',
        });
      }
    } else if (!prefillData) {
      this.materialSettingsFormGroups[boardType].patchValue({
        enabled: boardType === 'worktop',
      });
    }
  }

  public getOrderType(): DsOrderType {
    return this.formGroups[1].value.type === 'order' ? 'order' : 'offer';
  }

  private getCutoutKey(
    cutoutType: DsCutoutType,
    formField: DsCutoutTypeSettingsFields
  ): keyof DsOrder {
    switch (cutoutType) {
      case 'sink':
        switch (formField) {
          case 'enabled':
            throw new Error('Cutout enabled is not available');
          case 'brand':
            return 'sink_brand';
          case 'installation_type':
            return 'sink_installation_type';
          case 'specify_later':
            return 'specify_sink_later';
          case 'ind_text':
            return 'ind_sink_text';
          case 'order_number':
            return 'sink_order_nr';
          case 'type':
            return 'sink_type';
          case 'is_ind':
            throw new Error('Cutout is_ind is not available');
          default:
            throw neverError(formField);
        }
      case 'hob':
        switch (formField) {
          case 'enabled':
            throw new Error('Cutout enabled is not available');
          case 'brand':
            return 'hob_brand';
          case 'installation_type':
            return 'hob_installation_type';
          case 'specify_later':
            return 'specify_hob_later';
          case 'ind_text':
            return 'ind_hob_text';
          case 'order_number':
            return 'hob_order_nr';
          case 'type':
            return 'hob_type';
          case 'is_ind':
            throw new Error('Cutout is_ind is not available');

          default:
            throw neverError(formField);
        }
      case 'downdraft':
        switch (formField) {
          case 'enabled':
            throw new Error('Cutout enabled is not available');
          case 'brand':
            return 'downdraft_brand';
          case 'installation_type':
            return 'downdraft_installation_type';
          case 'specify_later':
            return 'specify_downdraft_later';
          case 'ind_text':
            return 'ind_downdraft_text';
          case 'order_number':
            return 'downdraft_order_nr';
          case 'type':
            return 'downdraft_type';
          case 'is_ind':
            throw new Error('Cutout is_ind is not available');

          default:
            throw neverError(formField);
        }
      case 'energy_box':
        switch (formField) {
          case 'enabled':
            throw new Error('Cutout enabled is not available');
          case 'brand':
            return 'energy_box_brand';
          case 'installation_type':
            return 'energy_box_installation_type';
          case 'specify_later':
            return 'specify_energy_box_later';
          case 'ind_text':
            return 'ind_energy_box_text';
          case 'order_number':
            return 'energy_box_order_nr';
          case 'type':
            return 'energy_box_type';
          case 'is_ind':
            throw new Error('Cutout is_ind is not available');

          default:
            throw neverError(formField);
        }
    }
  }

  private prefillCutoutType(
    prefillData: DsOrderEditData | null,
    cutoutType: DsCutoutType
  ): void {
    const indTextValue = prefillData?.order[
      this.getCutoutKey(cutoutType, 'ind_text')
    ] as unknown;
    const indText = typeof indTextValue === 'string' ? indTextValue : '';

    const isEnabled =
      prefillData &&
      (prefillData.order[this.getCutoutKey(cutoutType, 'brand')] ||
        prefillData.order[this.getCutoutKey(cutoutType, 'specify_later')] ||
        prefillData.order[this.getCutoutKey(cutoutType, 'ind_text')] ||
        prefillData.order[this.getCutoutKey(cutoutType, 'order_number')] ||
        prefillData.order[this.getCutoutKey(cutoutType, 'type')] ||
        prefillData.order[this.getCutoutKey(cutoutType, 'installation_type')]);

    this.cutoutSettingsFormGroups[cutoutType].setValue({
      enabled: isEnabled,
      brand: prefillData?.order[this.getCutoutKey(cutoutType, 'brand')] ?? null,
      installation_type:
        prefillData?.order[
          this.getCutoutKey(cutoutType, 'installation_type')
        ] ?? null,
      specify_later:
        !!prefillData?.order[this.getCutoutKey(cutoutType, 'specify_later')],
      ind_text: indText.replace(DS_CUTOUT_INDIVIDUAL_TEXT, ''),
      order_number:
        prefillData?.order[this.getCutoutKey(cutoutType, 'order_number')] ??
        null,
      type: prefillData?.order[this.getCutoutKey(cutoutType, 'type')] ?? null,
      is_ind: indText.includes(DS_CUTOUT_INDIVIDUAL_TEXT),
    });
  }

  public isFieldAvailableByUserType(
    userType: DsUserType,
    field: DsOrderFormStepFields
  ): boolean {
    if (userType === 'extern') {
      return (
        field !== 'service_type' &&
        field !== 'condition_date' &&
        field !== 'customerRefNr' &&
        field !== 'sales_RefNr'
      );
    } else {
      return true;
    }
  }

  public setActiveStep(
    step: DsOrderFormStep,
    shouldCheckDeliveryDateChanged = false
  ): void {
    this.shouldCheckDeliveryDateChanged = shouldCheckDeliveryDateChanged;
    this.activeStep$$.next(step);
  }

  public setActiveStepPrev(): void {
    let activeStep = this.activeStep$$.getValue();
    if (activeStep === undefined) {
      return;
    }
    if (activeStep > 1) {
      activeStep = (activeStep - 1) as DsOrderFormStep;
    }
    this.shouldCheckDeliveryDateChanged = true;
    this.activeStep$$.next(activeStep);
  }

  public setActiveStepNext(): void {
    let activeStep = this.activeStep$$.getValue();
    if (activeStep === undefined) {
      return;
    }
    if (activeStep < 5) {
      activeStep = (activeStep + 1) as DsOrderFormStep;
    }
    window.scrollTo(0, 0);
    this.shouldCheckDeliveryDateChanged = true;
    this.activeStep$$.next(activeStep);
  }

  get customerRefNrField(): FormControl {
    return this.formGroups[1].controls.customerRefNr;
  }

  get worktopMaterialNumber(): FormControl {
    return this.materialSettingsFormGroups.worktop.controls.material_number;
  }

  public getOrderFromForm(): DsOrder {
    const orderFormData: DsOrderFormData = Object.values(
      this.formGroups
    ).reduce((acc, formGroup) => {
      return { ...acc, ...formGroup.getRawValue() };
    }, {});

    return convertOrderFormToOrder({
      order: orderFormData,
      orderNumber: this.orderNumber,
      materialSettingsFormGroups: this.materialSettingsFormGroups,
      cutoutSettingsFormGroups: this.cutoutSettingsFormGroups,
      orderVersion: this.orderVersion ?? null,
      additionalArticlesFromAbas: this.additionalArticlesFromAbas,
    });
  }

  public getDataForApi(data?: Partial<DsOrder>): DsOrderApi {
    const order = this.getOrderFromForm();

    return orderConverter.toApi({
      ...order,
      ...data,
    });
  }

  public async saveAsOrder(
    mode: DsOrderSubmitMode,
    data?: Partial<DsOrder>
  ): Promise<void> {
    this.orderSubmitStatusChange$.next('submitting');

    const formData = this.getDataForApi(data);

    try {
      const response = await this.apiService.post<DsApiOrderResponse>(
        DS_API_ENDPOINTS.orders,
        formData
      );

      if (response.status === 'success') {
        const successOrder = orderConverter.fromApi(response.data);
        this.successOrder$.next(successOrder);
        this.orderNumber = response.data.order_number;
        this.orderVersion = response.data.versionn;

        if (mode === 'create') {
          this.orderSubmitStatusChange$.next('success');
        } else if (mode === 'update') {
          if (response.data.lock) {
            await this.router.navigate(['/orders']);
          } else {
            this.initOrder$.next();
            this.setActiveStep(5);
          }
        }

        this.uiService.setState({
          visibleFooter: null,
        });
      }

      if (response.status === 'error') {
        this.orderVersion = response.data?.versionn;
        this.orderSubmitStatusChange$.next('pending');

        let titleLabelKey = 'order-form.save-order-error-dialog.title';
        let textLabelKey = 'order-form.save-order-error-dialog.text';

        switch (response.error.code) {
          case 400:
            titleLabelKey = 'order-form.wrong-version-dialog.title';
            textLabelKey = 'order-form.wrong-version-dialog.text';
            break;
          case 403:
            titleLabelKey = 'order-form.no-edit-possible-dialog.title';
            textLabelKey = 'order-form.no-edit-possible-dialog.text';
            break;
          case 423:
            titleLabelKey = 'order-form.order-locked-dialog.title';
            textLabelKey = 'order-form.order-locked-dialog.text';
            break;
          case 503:
            titleLabelKey = 'order-form.file-upload-backend-error-dialog.title';
            textLabelKey = 'order-form.file-upload-backend-error-dialog.text';
            break;
          case 500:
            titleLabelKey = 'order-form.save-order-error-dialog.title';
            textLabelKey = 'order-form.save-order-error-dialog.text';
            break;
          default:
            throw neverError(response.error.code);
        }

        this.dialog.open<ErrorDialogComponent, DsErrorDialogInput>(
          ErrorDialogComponent,
          {
            width: DS_DIALOG_SIZE.M,
            maxWidth: DS_DIALOG_MAX_SIZE.M,
            data: {
              title: titleLabelKey,
              text: textLabelKey,
            },
          }
        );
      }
    } catch (error) {
      this.orderSubmitStatusChange$.next('pending');
    }
  }

  get preStore$(): Observable<DsOrderPreStore | undefined> {
    return this.preStore$$.asObservable();
  }

  public saveCurrentStep(): void {
    this.preStore$$.next({
      isOverallDirty: true,
      formGroupValues: this.getFormGroupValues(),
    });
  }

  public resetCurrentStep(): void {
    this.resetFormGroupsToPreStore('current');
  }

  public resetOverall(): void {
    this.resetFormGroupsToPreStore('overall');
  }

  public async saveOrder(): Promise<void> {
    if (
      this.prefillData?.order?.status === 'created' ||
      this.prefillData?.order?.status === 'offer_created'
    ) {
      await this.saveAsOrder('update');
      return;
    }

    if (this.hasBreakingChanges()) {
      this.openSaveDialogOverlay();
    } else {
      await this.saveAsOrder('update');
    }
  }

  private hasBreakingChanges(): boolean {
    const formGroups = this.formGroups;
    const materialSettingsFormGroups = this.materialSettingsFormGroups;
    const cutoutSettingsFormGroups = this.cutoutSettingsFormGroups;
    const prefillOrder = this.prefillData?.order;

    if (!prefillOrder) {
      return false;
    }

    if (formGroups[1].dirty || formGroups[2].dirty || formGroups[3].dirty) {
      return true;
    }

    if (formGroups[4].dirty) {
      if (!prefillOrder.specify_customer_data_later) {
        return true;
      } else {
        if (
          formGroups[4].controls.delivery_type.dirty ||
          formGroups[4].controls.measure.dirty ||
          formGroups[4].controls.measure_delivery.dirty ||
          formGroups[4].controls.montage_package_connection.dirty ||
          formGroups[4].controls.montage_package_connection_downdraft.dirty ||
          formGroups[4].controls.montage_package_connection_multi.dirty ||
          formGroups[4].controls.warehouse_address_retail.dirty ||
          formGroups[4].controls.warehouse_address.dirty ||
          formGroups[4].controls.warehouse_zip.dirty ||
          formGroups[4].controls.warehouse_city.dirty ||
          formGroups[4].controls.warehouse_country.dirty ||
          formGroups[4].controls.warehouse_tel_retail.dirty ||
          formGroups[4].controls.warehouse_mobile_retail.dirty ||
          formGroups[4].controls.warehouse_email_retail.dirty
        ) {
          return true;
        }
      }
    }

    if (formGroups[5].dirty) {
      return true;
    }

    if (materialSettingsFormGroups.worktop.dirty) {
      return true;
    }

    if (materialSettingsFormGroups.backwall.dirty) {
      return true;
    }

    if (materialSettingsFormGroups.wiper.dirty) {
      return true;
    }

    if (materialSettingsFormGroups.sidepanel.dirty) {
      return true;
    }

    if (!prefillOrder.specify_sink_later) {
      if (cutoutSettingsFormGroups.sink.dirty) {
        return true;
      }
    }

    if (!prefillOrder.specify_hob_later) {
      if (cutoutSettingsFormGroups.hob.dirty) {
        return true;
      }
    }

    if (!prefillOrder.specify_downdraft_later) {
      if (cutoutSettingsFormGroups.downdraft.dirty) {
        return true;
      }
    }

    if (!prefillOrder.specify_energy_box_later) {
      if (cutoutSettingsFormGroups.energy_box.dirty) {
        return true;
      }
    }

    return false;
  }

  private openSaveDialogOverlay(): void {
    const dialogRef = this.dialog.open<
      FormSaveDialogComponent,
      DsFormSaveDialogInput,
      DsFormSaveDialogOutput
    >(FormSaveDialogComponent, {
      width: DS_DIALOG_SIZE.M,
      maxWidth: DS_DIALOG_MAX_SIZE.M,
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(async (result) => {
        if (result?.action === 'save') {
          await this.saveAsOrder('update', { lock: true });
        }
      });
  }

  private getFormGroupValues(): DsOrderFormGroupValues {
    return {
      1: this.formGroups[1].value,
      2: this.formGroups[2].value,
      3: this.formGroups[3].value,
      4: this.formGroups[4].value,
      5: this.formGroups[5].value,
    };
  }

  private resetFormGroupsToPreStore(mode: 'overall' | 'current'): void {
    const preStore = this.preStore$$.getValue();
    if (!preStore?.formGroupValues) {
      return;
    }

    if (mode === 'overall') {
      this.preStore$$.next({
        isOverallDirty: false,
      });
      this._formGroups?.[1].patchValue(preStore.formGroupValues[1]);
      this._formGroups?.[2].patchValue(preStore.formGroupValues[2]);
      this._formGroups?.[3].patchValue(preStore.formGroupValues[3]);
      this._formGroups?.[4].patchValue(preStore.formGroupValues[4]);
      this._formGroups?.[5].patchValue(preStore.formGroupValues[5]);
    } else if (mode === 'current') {
      const activeStep = this.activeStep$$.getValue();
      if (!activeStep) {
        return;
      }
      this.formGroups[activeStep].patchValue(
        preStore.formGroupValues[activeStep]
      );
    }
  }

  public validateStep(step: DsOrderFormStep): boolean {
    let hasError = false;

    if (step === 2) {
      let isAnyBoardAdded = false;

      DS_BOARD_TYPES.forEach((boardType) => {
        const formGroup = this.materialSettingsFormGroups[boardType];

        if (
          formGroup.value.enabled === true &&
          !!formGroup.value.material_number
        ) {
          isAnyBoardAdded = true;
        }

        if (formGroup.value.enabled && formGroup.invalid) {
          this.onStepError(formGroup, 2);
          hasError = true;
        }

        formGroup.markAllAsTouched();
      });

      this.isAnyBoardAdded$.next(isAnyBoardAdded);
      if (!isAnyBoardAdded) {
        this.onStepError(undefined, 2);
        hasError = true;
      }

      DS_CUTOUT_TYPES.forEach((cutoutType) => {
        const formGroup = this.cutoutSettingsFormGroups[cutoutType];
        if (formGroup.value.enabled && formGroup.invalid) {
          this.onStepError(formGroup, 2);
          hasError = true;
        }
        formGroup.markAllAsTouched();
      });
    }

    this.formGroups[step].markAllAsTouched();

    if (this.formGroups[step].invalid) {
      this.onStepError(this.formGroups[step], step);
      hasError = true;
    }

    return !hasError;
  }

  private onStepError(
    formGroup: FormGroup | undefined,
    step: DsOrderFormStep
  ): void {
    formGroup?.updateValueAndValidity();
    this.setActiveStep(step);
    this.scrollToError$.next();
  }
}
