export type DsEnv = 'dev' | 'qa' | 'prod';
export type DsEnvironment = {
  env: DsEnv;
  dcupl: {
    baseUrl: string;
    apiUrl: string;
  };
  api: {
    baseUrl: string;
  };
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
  };
  assets: {
    baseUrl: string;
    materials: string;
    documents: string;
    edgeTreatment: string;
  };
};
