import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DS_ENVIRONMENT } from '@ds-environment';
import { AuthenticationService } from '@ds-services';
import {
  DS_API_ENDPOINTS,
  DS_API_ENDPOINTS_FILE_UPLOAD,
  DsApiParams,
} from '@ds-types';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  public async get<ResponseData>(
    endpoint: DS_API_ENDPOINTS | DS_API_ENDPOINTS_FILE_UPLOAD,
    params?: DsApiParams,
    authOff?: boolean
  ): Promise<ResponseData> {
    const url = `${DS_ENVIRONMENT.api.baseUrl}${endpoint}${params?.path ?? ''}`;
    const headers = new HttpHeaders(
      authOff
        ? {}
        : {
            Authorization: `Bearer ${await this.authenticationService.getAuthToken()}`,
          }
    );

    return firstValueFrom(
      this.httpClient.get<ResponseData>(url, { headers, responseType: 'json' })
    );
  }

  public async getBlob(
    endpoint: DS_API_ENDPOINTS | DS_API_ENDPOINTS_FILE_UPLOAD,
    params?: DsApiParams
  ): Promise<Blob> {
    const token = await this.authenticationService.getAuthToken();

    return firstValueFrom(
      this.httpClient.get(
        `${DS_ENVIRONMENT.api.baseUrl}${endpoint}${params?.path ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        }
      )
    );
  }

  public async post<ResponseData>(
    endpoint: DS_API_ENDPOINTS | DS_API_ENDPOINTS_FILE_UPLOAD,
    data: unknown
  ): Promise<ResponseData> {
    const token = await this.authenticationService.getAuthToken();

    return firstValueFrom(
      this.httpClient.post<ResponseData>(
        `${DS_ENVIRONMENT.api.baseUrl}${endpoint}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );
  }

  public async delete<ResponseData>(
    endpoint: DS_API_ENDPOINTS | DS_API_ENDPOINTS_FILE_UPLOAD,
    params?: DsApiParams
  ): Promise<ResponseData> {
    const token = await this.authenticationService.getAuthToken();

    return firstValueFrom(
      this.httpClient.delete<ResponseData>(
        `${DS_ENVIRONMENT.api.baseUrl}${endpoint}${params?.path ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );
  }
}
